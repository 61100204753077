import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription, map } from 'rxjs';

@Directive({
  selector: '[appLayout]'
})
/**
 * This directive is used to add size classe name to the host
 * element based on the current breakpoint.
 *
 * To view the classnames, check the breakPointMap property.
 */
export class LayoutDirective implements OnDestroy  {

  private breakPointMap = new Map ([
    [ Breakpoints.XSmall, 'bp-x-small' ],
    [ Breakpoints.Small, 'bp-small' ],
    [ Breakpoints.Medium, 'bp-medium' ],
    [ Breakpoints.Large, 'bp-large' ],
    [ Breakpoints.XLarge, 'bp-x-large' ],
    [ Breakpoints.Handset, 'bp-handset' ],
    [ Breakpoints.Tablet, 'bp-tablet' ],
    [ Breakpoints.Web, 'bp-web' ],
    [ Breakpoints.HandsetPortrait, 'bp-handset bp-portrait' ],
    [ Breakpoints.TabletPortrait, 'bp-tablet bp-portrait' ],
    [ Breakpoints.WebPortrait, 'bp-web bp-portrait' ],
    [ Breakpoints.HandsetLandscape, 'bp-handset bp-landscape' ],
    [ Breakpoints.TabletLandscape, 'bp-tablet bp-landscape' ],
    [ Breakpoints.WebLandscape, 'bp-web bp-landscape' ]
  ]);

  constructor(private breakpointObserver: BreakpointObserver,
              private elementRef: ElementRef<HTMLElement>) { }

  //Depending on application, we might not use all of these
  bp$: Subscription = this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
    Breakpoints.Handset,
    Breakpoints.Tablet,
    Breakpoints.Web,
    Breakpoints.HandsetPortrait,
    Breakpoints.TabletPortrait,
    Breakpoints.WebPortrait,
    Breakpoints.HandsetLandscape,
    Breakpoints.TabletLandscape,
    Breakpoints.WebLandscape
  ]).pipe(
    map ( breakPointData => {
        let toAdd: string[] = [];
        let toRemove: string[] = [];

        for ( let breakpointKey of Object.keys(breakPointData.breakpoints)){

          const classNames = this.breakPointMap.get(breakpointKey)?.split(" ") || [];

          if (breakPointData.breakpoints[breakpointKey]){
            toAdd = [...toAdd, ...classNames]
          }else {
            toRemove = [...toRemove, ...classNames];
          }
        }

        return {toAdd, toRemove};
      },
    )
  ).subscribe(breakpointsData => {
    this.elementRef.nativeElement.classList.remove(...breakpointsData.toRemove);
    this.elementRef.nativeElement.classList.add(...breakpointsData.toAdd);
  });

  ngOnDestroy(): void {
    this.bp$.unsubscribe();
  }

}
